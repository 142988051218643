export class Player {
  constructor(username) {
    this.username = username;
    this.characters = [];
    this.activeCount = 0;
  }

  addCharacter(character) {
    this.characters.push(character);
  }

  addCharacterString(str) {
    str.split(",")
      .map(ch => ch.trim())
      .sort(() => Math.random() - 0.5)
      .forEach(ch => { if (Boolean(ch)) this.characters.push(new Character(ch))});
  }

  updateActiveCount() {
    if (this.activeCount < this.characters.length) {
      this.activeCount = this.activeCount + 1;
    } 
    else {
      this.softReset();
    }
  }

  getNextFighter() {
    if (
      0 < this.characters.length
      && this.activeCount < this.characters.length
    ) {
      const fighter = this.characters[this.activeCount];
      this.updateActiveCount();
      return {
        player: this, 
        character: fighter,
      };
    }    
    // return false;
  }

  get availableFighterCount() {
    return this.count - this.activeCount;
  }

  softReset() {
    this.activeCount = 0;
  }

  updateCharacters(title) {
    this.characters = this.characters.filter(c => c.title !== title);
  }

  get count() {
    return this.characters.length;
  }
}

export class Character {
  constructor(title) {
    this.title = title;
    // this.iconSrc = iconSrc;
    // this.imgSrc = imgSrc;
  }
}

export class Match {
  constructor(slot1, slot2) {
    this.slot1 = slot1;
    this.slot2 = slot2;
    this.bye = slot2 ? false : true;
  }

  get versus() {
    return this.bye 
      ? `BYE for ${this.slot1.player.username}` 
      : `${this.slot1.character.title} vs. ${this.slot2?.character.title}`
  }
}

export class Tournament {
  constructor(participantCount) {
    this.participantCount = participantCount;
    this.participants = [];
    this.matches = [];
    this.noOfRound = 0;
  }

  addParticipant(player) {
    this.participants.push(player);
  }

  get isFull() {
    return this.participantCount === this.participants.length;
  }

  getRandomCharacter(username) {
    let fighter = null;

    while (!fighter) {
      const randomIndex = Math.floor(Math.random() * this.participants.length);
      const randomParticipant = this.participants[randomIndex];
      if (
        username !== randomParticipant.username
        && randomParticipant.availableFighterCount
      ) {
        fighter = randomParticipant.getNextFighter();
      }
    }

    return fighter;
  }

  getLeader() { // most overall when used with activeCounts reset, after that gets most available
    let leader = this.participants[0];
    let mostAvailable = leader.availableFighterCount;
    let marker = 1;

    while (marker < this.participants.length) {
      const participant = this.participants[marker];
      if (mostAvailable === participant.availableFighterCount) {
        if (Math.floor(Math.random() * 2)) { // basically random true or false
          leader = participant;
          mostAvailable = participant.availableFighterCount;
        }
      }
      else if (mostAvailable < participant.availableFighterCount) {
        leader = participant;
        mostAvailable = participant.availableFighterCount;
      } 
      marker += 1;
    }

    return leader;
  }

  getCharacterCount() {
    return this.participants.reduce((prev, curr) => prev + curr.count, 0);
  }

  resetActiveCounts() {
    this.participants.forEach(p => p.softReset());
  }

  // getLeftovers() {
  //   return this.participants.reduce((prev, curr) => {
  //     while (curr.availableFighterCount) {
  //       prev.push(new Match(curr.getNextFighter()));
  //     }
  //   }, []);
  // }

  noOfParticipantsWithFightersLeft() {
    return this.participants.reduce((prev, curr) => {
      if (curr.availableFighterCount) {
        return prev + 1;
      }
      return prev;
    }, 0);
  }

  matchRandomizer() {
    const matches = [];
    // this.resetActiveCounts();  // idk if we need both yet
    let leader = this.getLeader();

    if (this.getCharacterCount() % 2 === 1) { // if odd characters, set up BYE
      matches.push(new Match(leader.getNextFighter()));
    }

    let noOfParticipantsWithFightersLeft = this.noOfParticipantsWithFightersLeft();
    while (noOfParticipantsWithFightersLeft) {
      leader = this.getLeader();

      if (noOfParticipantsWithFightersLeft > 1) {
        matches.push(new Match(
          leader.getNextFighter(),
          this.getRandomCharacter(leader.username)
        ));
      }
      else {
        matches.push(new Match(leader.getNextFighter()));
      }

      noOfParticipantsWithFightersLeft = this.noOfParticipantsWithFightersLeft();
    }

    this.matches.push(matches);
    this.resetActiveCounts(); // idk if we need both yet
  }

  get currentMatches() {
    return this.matches[this.matches.length - 1];
  }

  get champion() {
    if (this.noOfParticipantsWithFightersLeft() === 1) {
      // this.resetActiveCounts();
      return this.getLeader().username;
    }
    return false;
  }
}

/*
console.log("hello tournament");

const joe = new Player("joe");
joe.addCharacter(new Character("joe1"));
joe.addCharacter(new Character("joe2"));
joe.addCharacter(new Character("joe3"));
joe.addCharacter(new Character("joe4"));
joe.addCharacter(new Character("joe5"));
console.log(joe.characters);

const nick = new Player("nick");
nick.addCharacter(new Character("nick1"));
nick.addCharacter(new Character("nick2"));
nick.addCharacter(new Character("nick3"));
nick.addCharacter(new Character("nick4"));
nick.addCharacter(new Character("nick5"));
console.log(nick.characters);

const dan = new Player("dan");
dan.addCharacter(new Character("dan1"));
dan.addCharacter(new Character("dan2"));
dan.addCharacter(new Character("dan3"));
dan.addCharacter(new Character("dan4"));
dan.addCharacter(new Character("dan5"));
console.log(dan.characters);

const gage = new Player("gage");
gage.addCharacter(new Character("gage1"));
gage.addCharacter(new Character("gage2"));
gage.addCharacter(new Character("gage3"));
gage.addCharacter(new Character("gage4"));
gage.addCharacter(new Character("gage5"));
console.log(gage.characters);

const john = new Player("john");
john.addCharacter(new Character("john1"));
john.addCharacter(new Character("john2"));
john.addCharacter(new Character("john3"));
john.addCharacter(new Character("john4"));
john.addCharacter(new Character("john5"));
console.log(john.characters);

const matt = new Player("matt");
matt.addCharacter(new Character("matt1"));
matt.addCharacter(new Character("matt2"));
matt.addCharacter(new Character("matt3"));
matt.addCharacter(new Character("matt4"));
matt.addCharacter(new Character("matt5"));
console.log(matt.characters);

const tourney = new Tournament(6);

tourney.addParticipant(joe);
tourney.addParticipant(nick);
tourney.addParticipant(john);
tourney.addParticipant(matt);
tourney.addParticipant(gage);
tourney.addParticipant(dan);

console.log(tourney);
console.log(tourney.isFull);

tourney.matchRandomizer();
console.log(tourney.currentMatches.length); 

tourney.currentMatches.forEach(match => {
  const player = match.slot1.player;
  const characterName = match.slot1.character.title;
  console.log(characterName);
  player.updateCharacters(characterName);
  // console.log(player);
})

tourney.matchRandomizer();
console.log(tourney.currentMatches.length); 
tourney.currentMatches.forEach(match => {
  console.log(match.slot1?.character.title);
  console.log(match.slot2?.character.title);
}); 

tourney.currentMatches.forEach(match => {
  if (match.bye) {
    // do nothing
  } 
  else {
    const player = match.slot1.player;
    const characterName = match.slot1.character.title;
    console.log(characterName);
    player.updateCharacters(characterName);
    // console.log(player);
  }
});

tourney.matchRandomizer();
console.log(tourney.currentMatches.length); 
tourney.currentMatches.forEach(match => {
  console.log(match.slot1.character.title);
  console.log(match.slot2?.character.title);
}); 

tourney.currentMatches.forEach(match => {
  if (match.bye) {
    // do nothing
  } 
  else {
    const player = match.slot1.player;
    const characterName = match.slot1.character.title;
    console.log(characterName);
    player.updateCharacters(characterName);
    // console.log(player);
  }
})

tourney.matchRandomizer();
console.log(tourney.currentMatches.length); 
tourney.currentMatches.forEach(match => {
  console.log(match.slot1.character.title);
  console.log(match.slot2?.character.title);
});

tourney.currentMatches.forEach(match => {
  if (match.bye) {
    // do nothing
  } 
  else {
    const player = match.slot1.player;
    const characterName = match.slot1.character.title;
    console.log(characterName);
    player.updateCharacters(characterName);
    // console.log(player);
  }
})

tourney.matchRandomizer();
console.log(tourney.currentMatches.length); 
tourney.currentMatches.forEach(match => {
  console.log(match.slot1.character.title);
  console.log(match.slot2?.character.title);
});

tourney.currentMatches.forEach(match => {
  if (match.bye) {
    // do nothing
  } 
  else {
    const player = match.slot1.player;
    const characterName = match.slot1.character.title;
    console.log(characterName);
    player.updateCharacters(characterName);
    // console.log(player);
  }
})

console.log(tourney.champion);
*/

// const joe = new Player("joe");
// joe.addCharacterString("Incineroar, Palutena, Ike, Simon, Shulk");
// console.log(joe.characters);