import React from "react";
import styled from "styled-components";
import * as Classes from "./classes";

const danTourney = new Classes.Tournament(6);

const nick = new Classes.Player("annemarie");
const john = new Classes.Player("CaVs");
const dan = new Classes.Player("DankÉ");
const gage = new Classes.Player("gag");
const matt = new Classes.Player("HennY II");
const joe = new Classes.Player("hOtSaUcE");

const participants = [nick, john, dan, gage, matt, joe];
participants.forEach(p => danTourney.addParticipant(p));

const InputWrap = styled.div`
  width: 100%;
  padding: 8px;
  display: flex;
  align-items: baseline;

  & input {
    margin-left: 8px;
    flex-grow: 1;
  }
`;

const Submit = styled.button`
  margin-top: 8px;
  padding: 8px;
  border: 2px solid #333;
  border-radius: 4px;
  color: #333;

  &:hover {
    border: 2px solid #2FF924;
  }

  &:focus {
    border: 2px solid #2FF924;
  }

  &:disabled {
    background-color: #DDD;
    border-color: #AAA;
    color: #AAA;
    cursor: initial;
  }
`;

function TourneyForm({ setRound }) {
  const [characters, setCharacters] = React.useState({});
  const [submitDisabled, setSubmitDisabled] = React.useState(false); // not being utilized

  const handleSubmitClick = () => {
    nick.addCharacterString(characters.nick);
    john.addCharacterString(characters.john);
    dan.addCharacterString(characters.dan);
    gage.addCharacterString(characters.gage);
    matt.addCharacterString(characters.matt);
    joe.addCharacterString(characters.joe);
    danTourney.matchRandomizer();
    setRound(1);
  }

  /*
  // need useEffect to change submitDisabled
  React.useEffect(() => {
    if (
      characters.nick
      && characters.john
      && characters.dan
      && characters.gage
      && characters.matt
      && characters.joe
    ) {
      setSubmitDisabled(false);
    }
    else {
      setSubmitDisabled(true);
    }
  }, [characters])
  */
  
  return (
    <>
      <h1>Welcome Dans!</h1>
      <h4>Enter your characters here:</h4>
      <InputWrap>
        <label htmlFor="nick">annemarie:</label>
        <input 
          type="text" 
          name="nick"
          onChange={(e) => setCharacters({...characters, nick: e.target.value})} 
        />
      </InputWrap>
      <InputWrap>
        <label htmlFor="john">CaVs:</label>
        <input 
          type="text" 
          name="john"
          onChange={(e) => setCharacters({...characters, john: e.target.value})} 
        />
      </InputWrap>
      <InputWrap>
        <label htmlFor="dan">DankÉ:</label>
        <input 
          type="text" 
          name="dan"
          onChange={(e) => setCharacters({...characters, dan: e.target.value})} 
        />
      </InputWrap>
      <InputWrap>
        <label htmlFor="gage">gag:</label>
        <input 
          type="text" 
          name="gage"
          onChange={(e) => setCharacters({...characters, gage: e.target.value})} 
        />
      </InputWrap>
      <InputWrap>
        <label htmlFor="matt">HennY II:</label>
        <input 
          type="text" 
          name="matt"
          onChange={(e) => setCharacters({...characters, matt: e.target.value})} 
        />
      </InputWrap>
      <InputWrap>
        <label htmlFor="joe">hOtSaUcE:</label>
        <input 
          type="text" 
          name="joe"
          onChange={(e) => setCharacters({...characters, joe: e.target.value})} 
        />
      </InputWrap>
      <Submit 
        disabled={submitDisabled}
        onClick={handleSubmitClick}
      >Submit</Submit>
    </>
  )
}

const MatchWrap = styled.div`
  display: flex;
  padding: 8px;
`;

const InfoBtn = styled.button`
  padding: 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Versus = styled.div`
  position: absolute;
  top: calc((var(--polygon-size) / 2) - 30px);
  left: calc((var(--polygon-size) / 2) - 30px);
  z-index: 1;
`;

const Bye = styled.div`
  position: absolute;
  bottom: 0;
  right: 8px;
  z-index: 1;
`;

const Header1 = styled.h1`
  padding: 8px;
  line-height: normal;
  color: #eee;
`;

const Header2 = styled.h2`
  padding: 0 8px;
  color: #eee;
`;

const Header3 = styled.h3`
  padding: 0 8px;
  color: #bbb;
`;

const PolygonBase = styled.button`
  width: var(--polygon-size);
  height: var(--polygon-size);
  background-color: aquamarine;
`;

const LeftPolygon = styled(PolygonBase)`
  clip-path: polygon(0 0, 100% 0, 0 100%, 0 100%);
  background-color: purple;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px;
`;

const RightPolygon = styled(PolygonBase)`
  clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 0% 100%);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 8px;
`;

const PolygonWrap = styled.div`
  --polygon-size: 250px;
  margin: 16px;
  border-radius: 8px;
  position: relative;
  background-color: #333;
  overflow: hidden;
  box-shadow: var(--SHADOW-FULL);
`;

function Match({ match, index, losers, setLosers }) {
  const [winner, setWinner] = React.useState(null);
  
  React.useLayoutEffect(() => {
    if (!losers[index]) {
      setWinner(null);
    }
  }, [losers]);

  return (
    <PolygonWrap>
      <LeftPolygon 
        onClick={() => {
          setWinner(match.slot1);
          if (match.bye) {
            console.log("BYE");
          }
          setLosers({...losers, [index]: match.slot2});
        }}
        style={{ 
          backgroundColor: (
            match.bye
            || (
              Boolean(winner)
              && winner.player.username === match.slot1.player.username
            )
          ) ? "green" : winner ? "red" : "blue",
        }}
      >
        <Header2>{match.slot1.character.title}</Header2>
        <Header3>{match.slot1.player.username}</Header3>
      </LeftPolygon>
      {
        !match.bye
        && (
          <>
            <Versus><Header1>VS</Header1></Versus>
            <RightPolygon 
              onClick={() => {
                setWinner(match.slot2);
                setLosers({...losers, [index]: match.slot1});
              }}
              style={{ 
                backgroundColor: (
                  winner
                  && winner.player.username === match.slot2.player.username
                ) ? "green" : winner ? "red" : "blue",
              }}
            >
              <Header3>{match.slot2.player.username}</Header3>
              <Header2>{match.slot2.character.title}</Header2>
            </RightPolygon>
          </>
        )
      }
      {
        match.bye
        && <Bye><Header1>BYE</Header1></Bye>
      }
    </PolygonWrap>
  )
}

const MatchesWrap = styled.div`
  max-width: 100%;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
`;

const NextRound = styled(Submit)`
  background-color: white;
  margin-bottom: 16px;
`;

function TourneyMatches() {
  const [losers, setLosers] = React.useState({});

  const handleNextRoundClick = () => {
    Object.values(losers).forEach(loser => {
      console.log(loser?.player.username);
      loser?.player.updateCharacters(loser.character.title);
    });
    danTourney.matchRandomizer();
    setLosers({});
  }

  return (
    <>
      {
        danTourney.currentMatches.length === 1
        && danTourney.currentMatches[0]["bye"]
        && <h1>WINNER!</h1>
      }
      <MatchesWrap>
        {
          danTourney.currentMatches?.map((match, i) => {
            return <Match match={match} key={i} index={i} losers={losers} setLosers={setLosers}/>
          })
        }
      </MatchesWrap>
      <NextRound
        disabled={!(Object.values(losers).length === danTourney.currentMatches.length)}
        onClick={handleNextRoundClick}
      >Next Round</NextRound>
    </>
  );
}

const Wrap = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FormWrap = styled.div`
  min-width: 50vw;
  min-height: 50vh;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: var(--SHADOW-FULL);
`;

function Tournament() {
  const [round, setRound] = React.useState(0);

  return (
    <Wrap>
      { 
        !Boolean(round)
        && (
          <FormWrap>
            <TourneyForm setRound={setRound}/>
          </FormWrap>
        )
      }
      {
        Boolean(round)
        && (
          <TourneyMatches/>
        )
      }
    </Wrap>
  )
}

export default Tournament;