import React from "react";
// import styled from "styled-components";
import {Helmet} from "react-helmet";
import GlobalStyles from "../sections/global/GlobalStyles";
import Tournament from "../sections/tournament/component";


function Tourney() {
  return (
    <>
    <Helmet>
      <title>Tourney Randomizer</title>
    </Helmet>
    <GlobalStyles/>
    <Tournament/>
    </>
  )
}

export default Tourney;
